import React from "react";
import { Link } from "react-router-dom";
import Sidebar from "./sidebar";

import blog1 from "../img/blog-1.png";
import blog2 from "../img/blog-2.png";
import blog3 from "../img/blog-3.png";
import blog4 from "../img/blog-4.png";
import blog5 from "../img/blog-5.png";
import blog6 from "../img/blog-6.png";
import blog7 from "../img/blog-7.png";
import blog8 from "../img/blog-8.png";
import blog9 from "../img/blog-9.png";
import blog10 from "../img/blog-10.png";
import blog11 from "../img/blog-11.png";
import blog12 from "../img/blog-12.png";
import blog13 from "../img/blog-13.png";
import blog14 from "../img/blog-14.png";
import blog15 from "../img/blog-15.png";
import blog16 from "../img/blog-16.png";
import blog17 from "../img/blog-17.png";
import blog18 from "../img/blog-18.png";
import blog19 from "../img/blog-19.png";
import blog20 from "../img/blog-20.png";


// import "./style.css";

const BlogList = () => {
  return (
    <section className="blog-page-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-7">
            <div className="blog-left">
              <div className="row">
                <div className="col-lg-12">
                  <Link
                    to="/The-Pros-and-Cons-of-Buying-New-Construction-vs-Existing-Homes"
                    className="news-box"
                    data-aos="fade-up"
                  >
                    <div className="news-img">
                      <img src={blog1} alt="img" />
                    </div>
                    <div className="news-text">
                      <h3>The Pros and Cons of Buying New Construction vs. Existing Homes</h3>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-12">
                  <Link
                    to="/Understanding-Closing-Costs"
                    className="news-box"
                    data-aos="fade-up"
                  >
                    <div className="news-img">
                      <img src={blog2} alt="img" />
                    </div>
                    <div className="news-text">
                      <h3>Understanding Closing Costs</h3>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-12">
                  <Link
                    to="/Navigating-the-Home-Inspection-Process"
                    className="news-box"
                    data-aos="fade-up"
                  >
                    <div className="news-img">
                      <img src={blog3} alt="img" />
                    </div>
                    <div className="news-text">
                      <h3>Navigating the Home Inspection Process</h3>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-12">
                  <Link
                    to="/How-to-Secure-the-Best-Mortgage-Rates-for-Your-Home-Purchase-in-India"
                    className="news-box"
                    data-aos="fade-up"
                  >
                    <div className="news-img">
                      <img src={blog4} alt="img" />
                    </div>
                    <div className="news-text">
                      <h3>How to Secure the Best Mortgage Rates for Your Home Purchase in India</h3>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-12">
                  <Link
                    to="/10-Essential-Tips-for-First-Time-Home-Buyers-in-India"
                    className="news-box"
                    data-aos="fade-up"
                  >
                    <div className="news-img">
                      <img src={blog5} alt="img" />
                    </div>
                    <div className="news-text">
                      <h3>10 Essential Tips for First-Time Home Buyers in India</h3>
                    </div>
                  </Link>
                </div>
              {/*6*/}
                <div className="col-lg-12">
                  <Link
                    to="/The-Impact-of-Location-on-Commercial-Property-Value"
                    className="news-box"
                    data-aos="fade-up"
                  >
                    <div className="news-img">
                      <img src={blog6} alt="img" />
                    </div>
                    <div className="news-text">
                      <h3>The Impact of Location on Commercial Property Value</h3>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-12">
                  <Link
                    to="/Office-vs-Retail"
                    className="news-box"
                    data-aos="fade-up"
                  >
                    <div className="news-img">
                      <img src={blog7} alt="img" />
                    </div>
                    <div className="news-text">
                      <h3>Office vs. Retail: Choosing the Right Commercial Property for Your Business</h3>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-12">
                  <Link
                    to="/Leasing-vs-Buying"
                    className="news-box"
                    data-aos="fade-up"
                  >
                    <div className="news-img">
                      <img src={blog8} alt="img" />
                    </div>
                    <div className="news-text">
                      <h3>Leasing vs. Buying</h3>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-12">
                  <Link
                    to="/Investing-in-Commercial-Real-Estate"
                    className="news-box"
                    data-aos="fade-up"
                  >
                    <div className="news-img">
                      <img src={blog9} alt="img" />
                    </div>
                    <div className="news-text">
                      <h3>Investing in Commercial Real Estate</h3>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-12">
                  <Link
                    to="/Evaluating-the-ROI-of-Commercial-Real-Estate-Investments"
                    className="news-box"
                    data-aos="fade-up"
                  >
                    <div className="news-img">
                      <img src={blog10} alt="img" />
                    </div>
                    <div className="news-text">
                      <h3>Evaluating the ROI of Commercial Real Estate Investments</h3>
                    </div>
                  </Link>
                </div>
                {/*11*/}
                <div className="col-lg-12">
                  <Link
                    to="/The-Benefits-of-Owning-a-Vacation-Home"
                    className="news-box"
                    data-aos="fade-up"
                  >
                    <div className="news-img">
                      <img src={blog11} alt="img" />
                    </div>
                    <div className="news-text">
                      <h3>The Benefits of Owning a Vacation Home</h3>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-12">
                  <Link
                    to="/Luxury-Home-Features"
                    className="news-box"
                    data-aos="fade-up"
                  >
                    <div className="news-img">
                      <img src={blog12} alt="img" />
                    </div>
                    <div className="news-text">
                      <h3>Luxury Home Features That Are Worth the Investment</h3>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-12">
                  <Link
                    to="/Luxury-Home-Features"
                    className="news-box"
                    data-aos="fade-up"
                  >
                    <div className="news-img">
                      <img src={blog13} alt="img" />
                    </div>
                    <div className="news-text">
                      <h3>Interior Design Trends for Modern Living Spaces</h3>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-12">
                  <Link
                    to="/How-to-Make-Your-Home-Eco-Friendly-and-Energy-Efficient"
                    className="news-box"
                    data-aos="fade-up"
                  >
                    <div className="news-img">
                      <img src={blog14} alt="img" />
                    </div>
                    <div className="news-text">
                      <h3>How to Make Your Home Eco-Friendly and Energy-Efficient</h3>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-12">
                  <Link
                    to="/Creating-the-Ultimate-Outdoor-Space-in-Your-Home"
                    className="news-box"
                    data-aos="fade-up"
                  >
                    <div className="news-img">
                      <img src={blog15} alt="img" />
                    </div>
                    <div className="news-text">
                      <h3>Creating the Ultimate Outdoor Space in Your Home: Landscaping Tips</h3>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-12">
                  <Link
                    to="/The-Future-of-Real-Estate"
                    className="news-box"
                    data-aos="fade-up"
                  >
                    <div className="news-img">
                      <img src={blog16} alt="img" />
                    </div>
                    <div className="news-text">
                      <h3>The Future of Real Estate: Trends and Predictions for the Next Decade</h3>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-12">
                  <Link
                    to="/Sustainability-in-Real-Estate"
                    className="news-box"
                    data-aos="fade-up"
                  >
                    <div className="news-img">
                      <img src={blog17} alt="img" />
                    </div>
                    <div className="news-text">
                      <h3>Sustainability in Real Estate: The Growing Importance of Green Buildings</h3>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-12">
                  <Link
                    to="/Real-Estate-and-the-COVID-19-Pandemic"
                    className="news-box"
                    data-aos="fade-up"
                  >
                    <div className="news-img">
                      <img src={blog18} alt="img" />
                    </div>
                    <div className="news-text">
                      <h3>Real Estate and the COVID-19 Pandemic: Lessons Learned and Adaptations</h3>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-12">
                  <Link
                    to="/Impact-of-Technology-on-the-Real-Estate-Industry"
                    className="news-box"
                    data-aos="fade-up"
                  >
                    <div className="news-img">
                      <img src={blog19} alt="img" />
                    </div>
                    <div className="news-text">
                      <h3>Impact of Technology on the Real Estate Industry: A Forward Look</h3>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-12">
                  <Link
                    to="/How-Demographic-Shifts-are-Shaping-the-Housing-Market"
                    className="news-box"
                    data-aos="fade-up"
                  >
                    <div className="news-img">
                      <img src={blog20} alt="img" />
                    </div>
                    <div className="news-text">
                      <h3>How Demographic Shifts are Shaping the Housing Market</h3>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <Sidebar/>
        </div>
      </div>
    </section>
  );
};

export default BlogList;