import React from "react";
import { Link } from "react-router-dom";
import Sidebar from "./sidebar";

import blog1 from "../img/blog-17.png";
import blog2 from "../img/blog-18.png";
import blog3 from "../img/blog-19.png";

import Img1 from "../img/blpl-1.jpg";
import Img2 from "../img/blpl-2.jpg";
import Img3 from "../img/blpl-3.jpg";

import {
  EmailShareButton,
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon
} from "react-share";

const BlogSingle = () => {
  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  return (
    <section className="blog-page-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-7">
            <div className="blog-left">
              <div className="row">
                <div className="col-lg-12">
                  <div className="news-img">
                    <img src={blog1} alt="img" />
                  </div>
                </div>
              </div>
              <div className="blog-left-content">
            <br/>
                <h2>Sustainability in Real Estate: The Growing Importance of Green Buildings</h2>
                 
    <div>
      <h2>Introduction:</h2>
      <p>
        In a world where environmental sustainability takes center stage, the real estate industry is undergoing
        a significant transformation. Indian home buyers and energy conservationists are increasingly prioritizing
        green buildings that not only promise comfortable living spaces but also contribute to a greener future.
        In this guide, we'll delve into the growing importance of sustainability in real estate, revealing how
        green buildings align with personal values and aspirations.
      </p>

      <h3>1. Sustainable Design Principles:</h3>
      <p>
        Sustainable building design focuses on eco-conscious architecture and responsible construction practices.
      </p>
      <p>
        Keywords: Sustainable building design, Eco-conscious architecture, Responsible construction
      </p>

      <h3>2. Energy Efficiency:</h3>
      <p>
        Energy-efficient homes are designed to lower utility bills and reduce the carbon footprint, aligning with
        environmental and economic goals.
      </p>
      <p>
        Keywords: Energy-efficient homes, Lower utility bills, Reduced carbon footprint
      </p>

      <h3>3. Environmentally Friendly Materials:</h3>
      <p>
        Green building materials and non-toxic finishes create healthy living environments while minimizing
        ecological impact.
      </p>
      <p>
        Keywords: Green building materials, Non-toxic finishes, Healthy living environments
      </p>

      <h3>4. Water Conservation Practices:</h3>
      <p>
        Water-saving fixtures and responsible water use contribute to eco-friendly plumbing and reduced water consumption.
      </p>
      <p>
        Keywords: Water-saving fixtures, Responsible water use, Eco-friendly plumbing
      </p>

      <h3>5. Renewable Energy Integration:</h3>
      <p>
        Incorporating solar power systems promotes clean energy production, energy independence, and reduced reliance
        on traditional power sources.
      </p>
      <p>
        Keywords: Solar power systems, Clean energy production, Energy independence
      </p>

      <h3>6. Indoor Air Quality:</h3>
      <p>
        Sustainable homes prioritize improved air quality, providing healthier living spaces free from allergens and pollutants.
      </p>
      <p>
        Keywords: Improved air quality, Healthier living spaces, Allergen-free homes
      </p>

      <h3>7. Smart Technology Integration:</h3>
      <p>
        Smart home automation enhances energy-efficient living and convenience while supporting sustainability goals.
      </p>
      <p>
        Keywords: Smart home automation, Energy-efficient living, Convenience and sustainability
      </p>

      <h3>8. Sustainable Communities:</h3>
      <p>
        Eco-friendly neighborhoods and green living practices foster community-driven sustainability efforts.
      </p>
      <p>
        Keywords: Eco-friendly neighborhoods, Green living, Community-driven sustainability
      </p>

      <h3>9. Certification and Compliance:</h3>
      <p>
        Green building certifications and adherence to environmental standards ensure verified sustainability in real estate.
      </p>
      <p>
        Keywords: Green building certifications, Environmental standards, Verified sustainability
      </p>

      <h3>10. Ethical and Responsible Living:</h3>
      <p>
        Making environmentally responsible choices in green living reflects personal values and a commitment to sustainable
        living practices.
      </p>
      <p>
        Keywords: Environmentally responsible choices, Green living values, Personal commitment to sustainability
      </p>

      <h2>Conclusion:</h2>
      <p>
        Choosing green buildings in the real estate market is not just a matter of bricks and mortar; it's about aligning
        your life with your values and the greater good.
      </p>
      <p>
        Keywords: Personalized sustainability choices, Relatable green living, Aspirational environmental impact
      </p>
      <p>
        As you explore the world of sustainable real estate, remember that your decisions are not just financial; they're
        about contributing to a sustainable and greener future—a place where your values and aspirations find their perfect home.
      </p>
      <p>
        Here's to building a sustainable future, one green building at a time!
      </p>
    </div>
    <br/>
                
                <div className="blog-left-content-share">
                  <h3>Share this post</h3>
                  <ul>
                    <li>
                      <FacebookShareButton url={"https://venkateshwararealty.co.in/Sustainability-in-Real-Estate"}>
                        <FacebookIcon size={40} round />
                      </FacebookShareButton>
                    </li>
                    <li>
                      <TwitterShareButton url={"https://venkateshwararealty.co.in/Sustainability-in-Real-Estate"}>
                        <TwitterIcon size={40} round />
                      </TwitterShareButton>
                    </li>
                    <li>
                      <WhatsappShareButton url={"https://venkateshwararealty.co.in/Sustainability-in-Real-Estate"}>
                        <WhatsappIcon size={40} round/>
                      </WhatsappShareButton>
                    </li>
                    <li>
                      <EmailShareButton url={"https://venkateshwararealty.co.in/Sustainability-in-Real-Estate"}>
                        <EmailIcon size={40} round/>
                      </EmailShareButton>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="blog-left-related-post">
                <h3>Related Blog Post</h3>
                <div className="row">
                  <div className="col-lg-6">
                    <Link to="/Real-Estate-and-the-COVID-19-Pandemic" className="news-box">
                      <div className="news-img">
                        <img src={blog2} alt="img" />
                      </div>
                      <div className="news-text">
                        <h3>Real Estate and the COVID-19 Pandemic: Lessons Learned and Adaptations</h3>
                      </div>
                    </Link>
                  </div>
                  <div className="col-lg-6">
                    <Link to="/Impact-of-Technology-on-the-Real-Estate-Industry" className="news-box">
                      <div className="news-img">
                        <img src={blog3} alt="img" />
                      </div>
                      <div className="news-text">
                        <h3>Impact of Technology on the Real Estate Industry: A Forward Look</h3>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>            
            </div>
          </div>
          <Sidebar />
        </div>
      </div>
    </section>
  );
};

export default BlogSingle;
