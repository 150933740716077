import React from "react";
import { Link } from "react-router-dom";

import Img1 from "../img/project-s-3.webp";
import Img2 from "../img/plan.png";
import Img3 from "../img/24-hours.png";
import Img4 from "../img/group.png";
import Img5 from "../img/best-price.png";
import Img6 from "../img/bedroom-2.jpg";
import Img7 from "../img/hallroom-2.png";
import Img8 from "../img/kitchen.jpg";

// import "./style.css";

const ProjectSingle2 = () => {
  return (
    <section className="project-details-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="project-details-top">
              <div className="project-details-top-img">
                <img src={Img1} alt="img" />
              </div>
              <div className="project-details-top-box">
                <h3>Project Details</h3>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="project-details-top-box-text">
                      <h5>Project</h5>
                      <p>Structure</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="project-details-top-box-text">
                      <h5>Location</h5>
                      <p>ANDHERI</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="project-details-top-box-text">
                      <h5>Year</h5>
                      <p>2013</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="project-details-top-box-text">
                      <h5>Type</h5>
                      <p>Residential</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="project-details-top-text">
              <h2>Best Structural Ideas</h2>
              <p>
                Ekopa Residences is a visionary residential complex nestled in the heart of the bustling Andheri neighborhood in Mumbai. It is designed to harmonize modern luxury with sustainable living, creating a one-of-a-kind living experience that reflects a commitment to the environment and the well-being of its residents.
              </p>
              <p>
                <h3>Amenities for Healthy Living - </h3><br/>
                At Ekopa Residences, the focus is not just on luxury, but also on promoting a healthy and balanced lifestyle for its residents. The building offers a range of amenities designed to enhance well-being:
              </p>
              <p>
                <h3>Location Advantages - </h3><br/>
              Nestled in Andheri, one of Mumbai's prime neighborhoods, Ekopa Residences provides convenient access to business hubs, entertainment options, educational institutions, and transportation networks. Residents can enjoy the vibrant city life while also relishing the tranquility and eco-conscious environment of their home.              </p>
            </div>
            <div className="project-overview">
              <div className="row">
                <div className="col-lg-3 col-6">
                  <div className="project-overview-box">
                    <img src={Img2} alt="img" />
                    <h5>Project size</h5>
                    <p>36000 sq ft</p>
                  </div>
                </div>
                <div className="col-lg-3 col-6">
                  <div className="project-overview-box">
                    <img src={Img3} alt="img" />
                    <h5>Project time</h5>
                    <p>2.5 Years</p>
                  </div>
                </div>
                <div className="col-lg-3 col-6">
                  <div className="project-overview-box">
                    <img src={Img4} alt="img" />
                    <h5>People worked</h5>
                    <p>220</p>
                  </div>
                </div>
                <div className="col-lg-3 col-6">
                  <div className="project-overview-box">
                    <img src={Img5} alt="img" />
                    <h5>Project cost</h5>
                    <p>₹100 cr</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="project-details-type">
              <div className="row">
                <div className="col-md-4">
                  <div className="details-box">
                    <div className="project-box project-details-box">
                      <img src={Img6} alt="img" />
                      <p>BEDROOM</p>
                    </div>
                    <div className="project-details-box-meta-text">
                      <p>
                        Introducing the epitome of comfort and serenity in our luxurious residences. Our bedrooms are meticulously designed to be your personal haven, where relaxation and style intertwine seamlessly.
                      </p>
                      
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="details-box">
                    <div className="project-box project-details-box">
                      <img src={Img7} alt="img" />
                      <p>HALLROOM</p>
                    </div>
                    <div className="project-details-box-meta-text">
                      <p>
                        Step into a world of luxury and versatility in our captivating living spaces. Our halls or living rooms are designed to be the heart of your home, a place to connect, entertain, and create lasting memories.
                      </p>
                      
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="details-box">
                    <div className="project-box project-details-box">
                      <img src={Img8} alt="img" />
                      <p>KITCHEN</p>
                    </div>
                    <div className="project-details-box-meta-text">
                      <p>
                        Discover the heart of culinary excellence in our designer kitchens. Thoughtfully planned and equipped, our kitchens make cooking a delightful experience and enlighten the chef in you.
                      </p>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ProjectSingle2;

