import React from "react";
import { BrowserRouter,Routes, Route, Outlet, Link } from "react-router-dom";

import Homepage from "./homepage";
import AboutPage from "./aboutpage";
import ProjectPage from "./projectpage";
import ServicePage from "./servicepage";
import ServiceSinglePage from "./servicesinglepage";
import PortfolioPage from "./portfoliopage";
import TeamPage from "./teampage";
import FaqPage from "./faqpage";
import ContactPage from "./contactpage";
import ErrorPage from "./errorpage";
import BlogPage from "./blogpage";
import BlogPage0 from "./blogpage0";
import BlogPage1 from "./blogpage1";
import BlogPage2 from "./blogpage2";
import BlogPage3 from "./blogpage3";

import BlogSinglePage from "./blogsinglepage";
import BlogSinglePage2 from "./blogsinglepage2";
import BlogSinglePage3 from "./blogsinglepage3";
import BlogSinglePage4 from "./blogsinglepage4";
import BlogSinglePage5 from "./blogsinglepage5";
import BlogSinglePage6 from "./blogsinglepage6";
import BlogSinglePage7 from "./blogsinglepage7";
import BlogSinglePage8 from "./blogsinglepage8";
import BlogSinglePage9 from "./blogsinglepage9";
import BlogSinglePage10 from "./blogsinglepage10";
import BlogSinglePage11 from "./blogsinglepage11";
import BlogSinglePage12 from "./blogsinglepage12";
import BlogSinglePage13 from "./blogsinglepage13";
import BlogSinglePage14 from "./blogsinglepage14";
import BlogSinglePage15 from "./blogsinglepage15";
import BlogSinglePage16 from "./blogsinglepage16";
import BlogSinglePage17 from "./blogsinglepage17";
import BlogSinglePage18 from "./blogsinglepage18";
import BlogSinglePage19 from "./blogsinglepage19";
import BlogSinglePage20 from "./blogsinglepage20";

import ProjectSinglePage from "./projectsinglepage";
import ProjectSinglePage2 from "./projectsinglepage2";
import ProjectSinglePage3 from "./projectsinglepage3";
import ProjectSinglePage4 from "./projectsinglepage4";
import ProjectSinglePage5 from "./projectsinglepage5";
import ProjectSinglePage6 from "./projectsinglepage6";
import ProjectSinglePage7 from "./projectsinglepage7";
import ProjectSinglePage8 from "./projectsinglepage8";
import ProjectSinglePage9 from "./projectsinglepage9";
import ProjectSinglePage10 from "./projectsinglepage10";
import ProjectSinglePage11 from "./projectsinglepage11";
import ProjectSinglePage12 from "./projectsinglepage12";
import ProjectSinglePage13 from "./projectsinglepage13";
import ProjectSinglePage14 from "./projectsinglepage14";
import ProjectSinglePage15 from "./projectsinglepage15";
import ProjectSinglePage16 from "./projectsinglepage16";
import ProjectSinglePage17 from "./projectsinglepage17";
import ProjectSinglePage18 from "./projectsinglepage18";
import ProjectSinglePage19 from "./projectsinglepage19";


import CurrentProject from "./current-project";


const AllRoute = () => {
  return (
    <div>
      <BrowserRouter basename="/">
        <Routes>
          <Route exact path="/" element={<Homepage/>} />
          <Route index path="home" element={<Homepage/>} />
          <Route path="about" element={<AboutPage/>} />
          <Route path="projects" element={<ProjectPage/>} />
          <Route path="ajanta" element={<ProjectSinglePage/>} />
          <Route path="ekopa" element={<ProjectSinglePage2/>} />
          <Route path="bluestar" element={<ProjectSinglePage3/>} />
          <Route path="eversmile" element={<ProjectSinglePage4/>} />
          <Route path="jaonitavilla" element={<ProjectSinglePage5/>} />
          <Route path="karmayog" element={<ProjectSinglePage6/>} />
          <Route path="krishnatara" element={<ProjectSinglePage7/>} />
          <Route path="sbi-pallavi" element={<ProjectSinglePage8/>} />
          <Route path="ratnadeep" element={<ProjectSinglePage9/>} />
          <Route path="ravikiran" element={<ProjectSinglePage10/>} />
          <Route path="sbi-milan" element={<ProjectSinglePage11/>} />
          <Route path="shraddha" element={<ProjectSinglePage12/>} />
          <Route path="sky-residency" element={<ProjectSinglePage13/>} />
          <Route path="vitthal-darshan" element={<ProjectSinglePage14/>} />
          <Route path="sudhanshu" element={<ProjectSinglePage15/>} />
          <Route path="yoga-yog" element={<ProjectSinglePage16/>} />
          <Route path="liberty-garden" element={<ProjectSinglePage17/>} />
          <Route path="mumbadevi-chs" element={<ProjectSinglePage18/>} />
          <Route path="samrat-chs" element={<ProjectSinglePage19/>} />
          <Route path="palash" element={<CurrentProject/>} />
          <Route path="service" element={<ServicePage/>} />
          <Route path="service-single" element={<ServiceSinglePage/>} />
          <Route path="team" element={<TeamPage/>} />
          <Route path="contact" element={<ContactPage/>} />
          <Route path="404" element={<ErrorPage/>} />
          <Route path="blog" element={<BlogPage/>} />
          <Route path="The-Pros-and-Cons-of-Buying-New-Construction-vs-Existing-Homes" element={<BlogSinglePage/>} />
          <Route path="Understanding-Closing-Costs" element={<BlogSinglePage2/>} />
          <Route path="Navigating-the-Home-Inspection-Process" element={<BlogSinglePage3/>} />
          <Route path="How-to-Secure-the-Best-Mortgage-Rates-for-Your-Home-Purchase-in-India" element={<BlogSinglePage4/>} />
          <Route path="10-Essential-Tips-for-First-Time-Home-Buyers-in-India" element={<BlogSinglePage5/>} />
          <Route path="The-Impact-of-Location-on-Commercial-Property-Value" element={<BlogSinglePage6/>} />
          <Route path="Office-vs-Retail" element={<BlogSinglePage7/>} />
          <Route path="Leasing-vs-Buying" element={<BlogSinglePage8/>} />
          <Route path="Investing-in-Commercial-Real-Estate" element={<BlogSinglePage9/>} />
          <Route path="Evaluating-the-ROI-of-Commercial-Real-Estate-Investments" element={<BlogSinglePage10/>} />
          <Route path="The-Benefits-of-Owning-a-Vacation-Home" element={<BlogSinglePage11/>} />
          <Route path="Luxury-Home-Features" element={<BlogSinglePage12/>} />
          <Route path="Interior-Design-Trends-for-Modern-Living-Spaces" element={<BlogSinglePage13/>} />
          <Route path="How-to-Make-Your-Home-Eco-Friendly-and-Energy-Efficient" element={<BlogSinglePage14/>} />
          <Route path="Creating-the-Ultimate-Outdoor-Space-in-Your-Home" element={<BlogSinglePage15/>} />
          <Route path="The-Future-of-Real-Estate" element={<BlogSinglePage16/>} />
          <Route path="Sustainability-in-Real-Estate" element={<BlogSinglePage17/>} />
          <Route path="Real-Estate-and-the-COVID-19-Pandemic" element={<BlogSinglePage18/>} />
          <Route path="Impact-of-Technology-on-the-Real-Estate-Industry" element={<BlogSinglePage19/>} />
          <Route path="How-Demographic-Shifts-are-Shaping-the-Housing-Market" element={<BlogSinglePage20/>} />
          <Route path="blog-buying-guide" element={<BlogPage0/>} />
          <Route path="blog-commercial" element={<BlogPage1/>} />
          <Route path="blog-lifestyle" element={<BlogPage2/>} />
          <Route path="blog-perspective" element={<BlogPage3/>} />

        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default AllRoute;
