import React from "react";
import { Link } from "react-router-dom";

import Img1 from "../img/f1.png";
import Img2 from "../img/f2.png";
import Img3 from "../img/f3.png";
import Img4 from "../img/f4.png";
import Img5 from "../img/f5.png";

// import "./style.css";

const Sidebar = () => {
  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  return (
    <div className="col-lg-4 col-md-5">
      
      <div className="blog-sidebar-categories" data-aos="fade-up">
        <div className="blog-sidebar-heading">
          <h3>Categories</h3>
        </div>
        <ul className="sidebar-content">
          <li>
            <Link to="/blog-buying-guide">
              Buying Guide <span>05</span>
            </Link>
          </li>
          <li>
            <Link to="/blog-commercial">
              Commercial <span>05</span>
            </Link>
          </li>
          <li>
            <Link to="/blog-lifestyle">
              Lifestyle <span>05</span>
            </Link>
          </li>
          <li>
            <Link to="/blog-perspective">
              Perspective <span>05</span>
            </Link>
          </li>
        </ul>
      </div>
      <div className="blog-sidebar-post" data-aos="fade-up">
        <div className="blog-sidebar-heading">
          <h3>Popular Post</h3>
        </div>
        <div className="sidebar-post-box" data-aos="fade-up">
          <div className="sidebar-post-img">
            <img src={Img1} alt="img" />
          </div>
          <div className="sidebar-post-text">
            <Link to="/10-Essential-Tips-for-First-Time-Home-Buyers-in-India">
              <h5>10 Essential Tips for First-Time Home Buyers in India</h5>
            </Link>
          </div>
        </div>
        <div className="sidebar-post-box">
          <div className="sidebar-post-img">
            <img src={Img2} alt="img" />
          </div>
          <div className="sidebar-post-text">
            <Link to="/Office-vs-Retail">
              <h5>Office vs. Retail: Choosing the Right Commercial Property for Your Business</h5>
            </Link>
          </div>
        </div>
        <div className="sidebar-post-box">
          <div className="sidebar-post-img">
            <img src={Img3} alt="img" />
          </div>
          <div className="sidebar-post-text">
            <Link to="/The-Benefits-of-Owning-a-Vacation-Home">
              <h5>The Benefits of Owning a Vacation Home</h5>
            </Link>
          </div>
        </div>
        <div className="sidebar-post-box">
          <div className="sidebar-post-img">
            <img src={Img4} alt="img" />
          </div>
          <div className="sidebar-post-text">
              <Link to="/Sustainability-in-Real-Estate">
              <h5>Sustainability in Real Estate: The Growing Importance of Green Buildings</h5>
            </Link>
          </div>
        </div>
        <div className="sidebar-post-box">
          <div className="sidebar-post-img">
            <img src={Img5} alt="img" />
          </div>
          <div className="sidebar-post-text">
            <Link to="/The-Future-of-Real-Estate">
              <h5>The Future of Real Estate: Trends and Predictions for the Next Decade</h5>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
