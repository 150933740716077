import React from "react";
import { Link } from "react-router-dom";

import Img1 from "../img/project-s-4.png";
import Img2 from "../img/plan.png";
import Img3 from "../img/24-hours.png";
import Img4 from "../img/group.png";
import Img5 from "../img/best-price.png";
import Img6 from "../img/bedroom.jpg";
import Img7 from "../img/hallroom.jpg";
import Img8 from "../img/kitchen.jpg";

// import "./style.css";

const ProjectSingle4 = () => {
  return (
    <section className="project-details-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="project-details-top">
              <div className="project-details-top-img">
                <img src={Img1} alt="img" />
              </div>
              <div className="project-details-top-box">
                <h3>Project Details</h3>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="project-details-top-box-text">
                      <h5>Project</h5>
                      <p>Architecture</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="project-details-top-box-text">
                      <h5>Location</h5>
                      <p>ANDHERI</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="project-details-top-box-text">
                      <h5>Year</h5>
                      <p>2011</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="project-details-top-box-text">
                      <h5>Type</h5>
                      <p>Residential</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="project-details-top-text">
              <h2>Best Interior Ideas</h2>
              <p>
                Eversmile Residences is a prestigious residential complex situated in the heart of the vibrant Andheri neighborhood in Mumbai. It's not just a building; it's a lifestyle, designed to bring joy, comfort, and a sense of community to its residents.
              </p>
              <p>
                <h3>Architectural Marvel - </h3>

                Eversmile Residences stands as a modern architectural marvel, boasting a unique design that seamlessly blends contemporary aesthetics with practicality. The building's façade is a harmonious fusion of glass, steel, and sustainable materials, creating a striking visual presence that captures the essence of urban living.              </p>
              <p>
                <h3>Community and Lifestyle - </h3>
                Eversmile Residences is more than just a building; it's a vibrant and inclusive community where residents become part of a larger family. Regular social events, workshops, and cultural programs are organized to foster a sense of belonging and make lasting memories.
              </p>
            </div>
            <div className="project-overview">
              <div className="row">
                <div className="col-lg-3 col-6">
                  <div className="project-overview-box">
                    <img src={Img2} alt="img" />
                    <h5>Project size</h5>
                    <p>50200 sq ft</p>
                  </div>
                </div>
                <div className="col-lg-3 col-6">
                  <div className="project-overview-box">
                    <img src={Img3} alt="img" />
                    <h5>Project time</h5>
                    <p>3.5 Years</p>
                  </div>
                </div>
                <div className="col-lg-3 col-6">
                  <div className="project-overview-box">
                    <img src={Img4} alt="img" />
                    <h5>People worked</h5>
                    <p>380</p>
                  </div>
                </div>
                <div className="col-lg-3 col-6">
                  <div className="project-overview-box">
                    <img src={Img5} alt="img" />
                    <h5>Project cost</h5>
                    <p>₹300 cr</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="project-details-type">
              <div className="row">
                <div className="col-md-4">
                  <div className="details-box">
                    <div className="project-box project-details-box">
                      <img src={Img6} alt="img" />
                      <p>BEDROOM</p>
                    </div>
                    <div className="project-details-box-meta-text">
                      <p>
                        Discover the epitome of comfort and relaxation in our thoughtfully designed bedrooms. Each bedroom in our exceptional building is more than just a space to rest; it's a sanctuary of serenity and style.
                      </p>
                      
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="details-box">
                    <div className="project-box project-details-box">
                      <img src={Img7} alt="img" />
                      <p>HALLROOM</p>
                    </div>
                    <div className="project-details-box-meta-text">
                      <p>
                       Experience the pinnacle of modern living in our exquisite living spaces. Our halls or living rooms are designed to be the heart of your home, where you can relax, entertain, and make cherished memories.
                      </p>
                      
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="details-box">
                    <div className="project-box project-details-box">
                      <img src={Img8} alt="img" />
                      <p>KITCHEN</p>
                    </div>
                    <div className="project-details-box-meta-text">
                      <p>
                        Discover culinary excellence in our meticulously planned kitchens. Whether you're a passionate cook or a casual chef, our kitchens are designed to be your culinary haven.
                      </p>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ProjectSingle4;
