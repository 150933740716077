import React from "react";
import { Link } from "react-router-dom";

import Img1 from "../img/project-s-5.webp";
import Img2 from "../img/plan.png";
import Img3 from "../img/24-hours.png";
import Img4 from "../img/group.png";
import Img5 from "../img/best-price.png";
import Img6 from "../img/bedroom-5.jpg";
import Img7 from "../img/hallroom-5.jpg";
import Img8 from "../img/kitchen-4.jpg";

// import "./style.css";

const ProjectSingle5 = () => {
  return (
    <section className="project-details-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="project-details-top">
              <div className="project-details-top-img">
                <img src={Img1} alt="img" />
              </div>
              <div className="project-details-top-box">
                <h3>Project Details</h3>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="project-details-top-box-text">
                      <h5>Project</h5>
                      <p>Interior</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="project-details-top-box-text">
                      <h5>Location</h5>
                      <p>ANDHERI</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="project-details-top-box-text">
                      <h5>Year</h5>
                      <p>2017</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="project-details-top-box-text">
                      <h5>Type</h5>
                      <p>Residential</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="project-details-top-text">
              <h2>Best Interior Ideas</h2>
              <p>
                  Jaonitavilla Residences is a prestigious residential haven situated in the heart of Andheri, one of Mumbai's most sought-after neighborhoods. This exquisite building is designed to encapsulate a perfect blend of luxury, comfort, and timeless elegance.
              </p>
              <p>
                <h3>Convenience and Connectivity - </h3>
                Jaonitavilla Residences is strategically located to provide the perfect balance between convenience and tranquility:
              </p>
              <p>
              <h3>Conclusion - </h3>
                Experience the epitome of luxury living at Jaonitavilla Residences. It's not just a residence; it's a lifestyle choice where every detail is designed to provide you with comfort, elegance, and a sense of belonging. Make Jaonitavilla your home, where luxury meets community, and every day feels like a celebration.
              </p>
            </div>
            <div className="project-overview">
              <div className="row">
                <div className="col-lg-3 col-6">
                  <div className="project-overview-box">
                    <img src={Img2} alt="img" />
                    <h5>Project size</h5>
                    <p>5200 sq ft</p>
                  </div>
                </div>
                <div className="col-lg-3 col-6">
                  <div className="project-overview-box">
                    <img src={Img3} alt="img" />
                    <h5>Project time</h5>
                    <p>3.5 Years</p>
                  </div>
                </div>
                <div className="col-lg-3 col-6">
                  <div className="project-overview-box">
                    <img src={Img4} alt="img" />
                    <h5>People worked</h5>
                    <p>255</p>
                  </div>
                </div>
                <div className="col-lg-3 col-6">
                  <div className="project-overview-box">
                    <img src={Img5} alt="img" />
                    <h5>Project cost</h5>
                    <p>₹190 cr</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="project-details-type">
              <div className="row">
                <div className="col-md-4">
                  <div className="details-box">
                    <div className="project-box project-details-box">
                      <img src={Img6} alt="img" />
                      <p>BEDROOM</p>
                    </div>
                    <div className="project-details-box-meta-text">
                      <p>
                        Welcome to the epitome of comfort and relaxation within Jaonitavilla Residences. Our bedrooms are designed to be your personal sanctuary, a retreat from the bustling world outside.
                      </p>
                      
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="details-box">
                    <div className="project-box project-details-box">
                      <img src={Img7} alt="img" />
                      <p>HALLROOM</p>
                    </div>
                    <div className="project-details-box-meta-text">
                      <p>
                        Experience the heart of Jaonitavilla Residences – the exquisite living spaces that bring families and friends together. Our halls or living rooms are designed to be versatile, elegant, and welcoming.                      </p>
                      
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="details-box">
                    <div className="project-box project-details-box">
                      <img src={Img8} alt="img" />
                      <p>KITCHEN</p>
                    </div>
                    <div className="project-details-box-meta-text">
                      <p>
                        Discover the joy of cooking and culinary creativity in our meticulously designed kitchens. Jaonitavilla's kitchens are thoughtfully planned to be functional, efficient, and appealing.
                      </p>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ProjectSingle5;
