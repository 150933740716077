import React from "react";
import { Link } from "react-router-dom";

import Img1 from "../img/project-16.webp";
import Img2 from "../img/plan.png";
import Img3 from "../img/24-hours.png";
import Img4 from "../img/group.png";
import Img5 from "../img/best-price.png";
import Img6 from "../img/bedroom-5.jpg";
import Img7 from "../img/hallroom-4.jpg";
import Img8 from "../img/kitchen-10.jpg";

// import "./style.css";

const ProjectSingle13 = () => {
  return (
    <section className="project-details-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="project-details-top">
              <div className="project-details-top-img">
                <img src={Img1} alt="img" />
              </div>
              <div className="project-details-top-box">
                <h3>Project Details</h3>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="project-details-top-box-text">
                      <h5>Project</h5>
                      <p>Interior</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="project-details-top-box-text">
                      <h5>Location</h5>
                      <p>Vile Parle</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="project-details-top-box-text">
                      <h5>Year</h5>
                      <p>2020</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="project-details-top-box-text">
                      <h5>Type</h5>
                      <p>Residential</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="project-details-top-text">
              <h2>Best Interior Ideas</h2>
              <p>
                Yoga Yog Residences is a serene oasis nestled in the heart of Vile Parle, Mumbai's vibrant and culturally rich neighborhood. Designed to harmonize modern living with holistic wellness, this exclusive residential haven invites you to embrace a balanced lifestyle that nurtures the mind, body, and soul.              </p>
              <p>
                <h3> - Architectural Marvel - </h3>

                Yoga Yog Residences embodies architectural harmony, seamlessly blending contemporary design with the principles of tranquility.              </p>
              <p>
                <h3> - Community and Lifestyle - </h3>
                Yoga Yog Residences is not just a building; it's a community of like-minded individuals seeking a harmonious way of life.              
                Regular wellness workshops, mindfulness sessions, and yoga classes foster a strong sense of community, enabling residents to connect and grow together.
                </p>
              <p>
                <h3> - Conclusion - </h3>
                Embrace the spirit of well-being and tranquility at Yoga Yog Residences. It's more than just a residence; it's a way of life that nurtures your physical, mental, and spiritual health. Experience the essence of holistic living in the heart of Vile Parle, where every day brings you closer to your wellness goals and a sense of inner harmony.              </p>
            </div>
            <div className="project-overview">
              <div className="row">
                <div className="col-lg-3 col-6">
                  <div className="project-overview-box">
                    <img src={Img2} alt="img" />
                    <h5>Project size</h5>
                    <p>52000 sq ft</p>
                  </div>
                </div>
                <div className="col-lg-3 col-6">
                  <div className="project-overview-box">
                    <img src={Img3} alt="img" />
                    <h5>Project time</h5>
                    <p>4 Years</p>
                  </div>
                </div>
                <div className="col-lg-3 col-6">
                  <div className="project-overview-box">
                    <img src={Img4} alt="img" />
                    <h5>People worked</h5>
                    <p>210</p>
                  </div>
                </div>
                <div className="col-lg-3 col-6">
                  <div className="project-overview-box">
                    <img src={Img5} alt="img" />
                    <h5>Project cost</h5>
                    <p>₹ 195cr</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="project-details-type">
              <div className="row">
                <div className="col-md-4">
                  <div className="details-box">
                    <div className="project-box project-details-box">
                      <img src={Img6} alt="img" />
                      <p>BEDROOM</p>
                    </div>
                    <div className="project-details-box-meta-text">
                      <p>
Welcome to the peaceful haven of Yoga Yog Residences. Our bedrooms are thoughtfully designed to provide a seamless blend of comfort, style, and mindfulness.                      </p>
                      
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="details-box">
                    <div className="project-box project-details-box">
                      <img src={Img7} alt="img" />
                      <p>HALLROOM</p>
                    </div>
                    <div className="project-details-box-meta-text">
                      <p>
Experience the heart of Yoga Yog Residences – our versatile and inviting living spaces. Our halls or living rooms are designed to be the hub of daily life and mindful connections.                      </p>
                      
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="details-box">
                    <div className="project-box project-details-box">
                      <img src={Img8} alt="img" />
                      <p>KITCHEN</p>
                    </div>
                    <div className="project-details-box-meta-text">
                      <p>
Experience culinary mindfulness in our meticulously designed kitchens at Yoga Yog Residences. Our kitchens are more than functional; they inspire conscious cooking and well-being.                      </p>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ProjectSingle13;
