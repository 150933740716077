import React from "react";
import { Link } from "react-router-dom";

import Img1 from "../img/project-s-8.jpg";
import Img2 from "../img/plan.png";
import Img3 from "../img/24-hours.png";
import Img4 from "../img/group.png";
import Img5 from "../img/best-price.png";
import Img6 from "../img/bedroom-8.jpg";
import Img7 from "../img/hallroom-5.jpg";
import Img8 from "../img/kitchen-9.jpg";

// import "./style.css";

const ProjectSingle8 = () => {
  return (
    <section className="project-details-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="project-details-top">
              <div className="project-details-top-img">
                <img src={Img1} alt="img" />
              </div>
              <div className="project-details-top-box">
                <h3>Project Details</h3>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="project-details-top-box-text">
                      <h5>Project</h5>
                      <p>Interior</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="project-details-top-box-text">
                      <h5>Location</h5>
                      <p>MALAD</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="project-details-top-box-text">
                      <h5>Year</h5>
                      <p>2013</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="project-details-top-box-text">
                      <h5>Type</h5>
                      <p>Residential</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="project-details-top-text">
              <h2>Best Interior Ideas</h2>
              <p>
                Pallavi Residences stands as a symbol of elegance and modern living in the vibrant neighborhood of Malad, Mumbai. This luxurious residential haven is meticulously designed to offer a perfect blend of comfort, convenience, and style, providing residents with an exceptional living experience.
              </p>
              <p>
                <h3>Convenience and Connectivity - </h3>
                Pallavi Residences is strategically located to provide the perfect blend of urban convenience and peaceful living:
              </p>
              <p>
                <h3> - Conclusion - </h3>
                Pallavi Residences offers a lifestyle that exceeds expectations, where luxury meets convenience, and every detail is designed to enhance your quality of life. Experience the epitome of modern living in the heart of Malad. Make Pallavi Residences your home, where you'll discover a haven of comfort, style, and a vibrant community, all within the bustling city of Mumbai.
              </p>
            </div>
            <div className="project-overview">
              <div className="row">
                <div className="col-lg-3 col-6">
                  <div className="project-overview-box">
                    <img src={Img2} alt="img" />
                    <h5>Project size</h5>
                    <p>38000 sq ft</p>
                  </div>
                </div>
                <div className="col-lg-3 col-6">
                  <div className="project-overview-box">
                    <img src={Img3} alt="img" />
                    <h5>Project time</h5>
                    <p>2 Years</p>
                  </div>
                </div>
                <div className="col-lg-3 col-6">
                  <div className="project-overview-box">
                    <img src={Img4} alt="img" />
                    <h5>People worked</h5>
                    <p>160</p>
                  </div>
                </div>
                <div className="col-lg-3 col-6">
                  <div className="project-overview-box">
                    <img src={Img5} alt="img" />
                    <h5>Project cost</h5>
                    <p>₹ 125cr</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="project-details-type">
              <div className="row">
                <div className="col-md-4">
                  <div className="details-box">
                    <div className="project-box project-details-box">
                      <img src={Img6} alt="img" />
                      <p>BEDROOM</p>
                    </div>
                    <div className="project-details-box-meta-text">
                      <p>
                        Step into a realm of luxury and tranquility within Pallavi Residences. Our bedrooms are designed to be your personal haven, a place where comfort and style intertwine seamlessly.
                      </p>
                      
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="details-box">
                    <div className="project-box project-details-box">
                      <img src={Img7} alt="img" />
                      <p>HALLROOM</p>
                    </div>
                    <div className="project-details-box-meta-text">
                      <p>
                        Experience the heart of Pallavi Residences in our versatile and inviting living spaces. Our halls or living rooms are designed to be the center of your daily life and special gatherings.
                      </p>
                      
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="details-box">
                    <div className="project-box project-details-box">
                      <img src={Img8} alt="img" />
                      <p>KITCHEN</p>
                    </div>
                    <div className="project-details-box-meta-text">
                      <p>
                        Discover culinary excellence in our meticulously planned kitchens. Pallavi's kitchens are designed to be functional, beautiful, and inspire your culinary creations.
                      </p>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ProjectSingle8;
