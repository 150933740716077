import React from "react";
import { Link } from "react-router-dom";
import Sidebar from "./sidebar";

import blog16 from "../img/blog-16.png";
import blog17 from "../img/blog-17.png";
import blog18 from "../img/blog-18.png";
import blog19 from "../img/blog-19.png";
import blog20 from "../img/blog-20.png";


// import "./style.css";

const BlogList = () => {
  return (
    <section className="blog-page-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-7">
            <div className="blog-left">
              <div className="row">
                
                <div className="col-lg-12">
                  <Link
                    to="/The-Future-of-Real-Estate"
                    className="news-box"
                    data-aos="fade-up"
                  >
                    <div className="news-img">
                      <img src={blog16} alt="img" />
                    </div>
                    <div className="news-text">
                      <h3>The Future of Real Estate: Trends and Predictions for the Next Decade</h3>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-12">
                  <Link
                    to="/Sustainability-in-Real-Estate"
                    className="news-box"
                    data-aos="fade-up"
                  >
                    <div className="news-img">
                      <img src={blog17} alt="img" />
                    </div>
                    <div className="news-text">
                      <h3>Sustainability in Real Estate: The Growing Importance of Green Buildings</h3>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-12">
                  <Link
                    to="/Real-Estate-and-the-COVID-19-Pandemic"
                    className="news-box"
                    data-aos="fade-up"
                  >
                    <div className="news-img">
                      <img src={blog18} alt="img" />
                    </div>
                    <div className="news-text">
                      <h3>Real Estate and the COVID-19 Pandemic: Lessons Learned and Adaptations</h3>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-12">
                  <Link
                    to="/Impact-of-Technology-on-the-Real-Estate-Industry"
                    className="news-box"
                    data-aos="fade-up"
                  >
                    <div className="news-img">
                      <img src={blog19} alt="img" />
                    </div>
                    <div className="news-text">
                      <h3>Impact of Technology on the Real Estate Industry: A Forward Look</h3>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-12">
                  <Link
                    to="/How-Demographic-Shifts-are-Shaping-the-Housing-Market"
                    className="news-box"
                    data-aos="fade-up"
                  >
                    <div className="news-img">
                      <img src={blog20} alt="img" />
                    </div>
                    <div className="news-text">
                      <h3>How Demographic Shifts are Shaping the Housing Market</h3>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <Sidebar/>
        </div>
      </div>
    </section>
  );
};

export default BlogList;