import React from "react";
import { Link } from "react-router-dom";

import Img1 from "../img/project-s-12.webp";
import Img2 from "../img/plan.png";
import Img3 from "../img/24-hours.png";
import Img4 from "../img/group.png";
import Img5 from "../img/best-price.png";
import Img6 from "../img/bedroom-6.jpg";
import Img7 from "../img/hallroom-4.jpg";
import Img8 from "../img/kitchen-4.jpg";

// import "./style.css";

const ProjectSingle12 = () => {
  return (
    <section className="project-details-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="project-details-top">
              <div className="project-details-top-img">
                <img src={Img1} alt="img" />
              </div>
              <div className="project-details-top-box">
                <h3>Project Details</h3>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="project-details-top-box-text">
                      <h5>Project</h5>
                      <p>Interior</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="project-details-top-box-text">
                      <h5>Location</h5>
                      <p>BORIVALI</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="project-details-top-box-text">
                      <h5>Year</h5>
                      <p>2013</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="project-details-top-box-text">
                      <h5>Type</h5>
                      <p>Residential</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="project-details-top-text">
              <h2>Best Interior Ideas</h2>
              <p>
                Shraddha Enclave stands as a symbol of serene living in the heart of Borivali, one of Mumbai's most thriving and family-friendly neighborhoods. This exclusive residential haven is thoughtfully designed to offer a perfect blend of comfort, community, and modern convenience, providing residents with an exceptional living experience.
              </p>
              <p>
                <h3> - Convenience and Connectivity - </h3>

                Shraddha Enclave is strategically located to offer the perfect balance between urban convenience and a peaceful retreat:
              </p>
              <p>
                <h3> - Community and Lifestyle - </h3>

                Shraddha Enclave is more than just a building; it's a community where residents can create cherished memories and embrace a high-quality lifestyle:
              </p>
              <p>
                <h3> - Conclusion - </h3>

                Experience the tranquility and comfort of Shraddha Enclave. It's not just a residence; it's a lifestyle choice where every detail is designed to provide you with comfort, community, and a serene retreat in the heart of Borivali. Embrace the Shraddha way of life, where every day feels like a celebration of modern living in one of Mumbai's most cherished neighborhoods.
              </p>
            </div>
            <div className="project-overview">
              <div className="row">
                <div className="col-lg-3 col-6">
                  <div className="project-overview-box">
                    <img src={Img2} alt="img" />
                    <h5>Project size</h5>
                    <p>45000 sq ft</p>
                  </div>
                </div>
                <div className="col-lg-3 col-6">
                  <div className="project-overview-box">
                    <img src={Img3} alt="img" />
                    <h5>Project time</h5>
                    <p>3 Years</p>
                  </div>
                </div>
                <div className="col-lg-3 col-6">
                  <div className="project-overview-box">
                    <img src={Img4} alt="img" />
                    <h5>People worked</h5>
                    <p>255</p>
                  </div>
                </div>
                <div className="col-lg-3 col-6">
                  <div className="project-overview-box">
                    <img src={Img5} alt="img" />
                    <h5>Project cost</h5>
                    <p>₹ 205cr</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="project-details-type">
              <div className="row">
                <div className="col-md-4">
                  <div className="details-box">
                    <div className="project-box project-details-box">
                      <img src={Img6} alt="img" />
                      <p>BEDROOM</p>
                    </div>
                    <div className="project-details-box-meta-text">
                      <p>
                        Step into a world of comfort and relaxation within Shraddha Residences. Our bedrooms are meticulously designed to provide the perfect blend of serenity, style, and practicality.
                      </p>
                      
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="details-box">
                    <div className="project-box project-details-box">
                      <img src={Img7} alt="img" />
                      <p>HALLROOM</p>
                    </div>
                    <div className="project-details-box-meta-text">
                      <p>
                        Experience the heart of Shraddha Residences – our versatile and inviting living spaces. Our halls or living rooms are designed to be the center of your daily life and special gatherings
                      </p>
                      
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="details-box">
                    <div className="project-box project-details-box">
                      <img src={Img8} alt="img" />
                      <p>KITCHEN</p>
                    </div>
                    <div className="project-details-box-meta-text">
                      <p>
                        Discover culinary excellence in our meticulously designed kitchens. Shraddha's kitchens are a perfect blend of functionality and aesthetics, inspiring your culinary creativity.
                      </p>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ProjectSingle12;
