import React from "react";
import { Link } from "react-router-dom";
import Sidebar from "./sidebar";


import blog11 from "../img/blog-11.png";
import blog12 from "../img/blog-12.png";
import blog13 from "../img/blog-13.png";
import blog14 from "../img/blog-14.png";
import blog15 from "../img/blog-15.png";


// import "./style.css";

const BlogList = () => {
  return (
    <section className="blog-page-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-7">
            <div className="blog-left">
              <div className="row">
                
                {/*11*/}
                <div className="col-lg-12">
                  <Link
                    to="/The-Benefits-of-Owning-a-Vacation-Home"
                    className="news-box"
                    data-aos="fade-up"
                  >
                    <div className="news-img">
                      <img src={blog11} alt="img" />
                    </div>
                    <div className="news-text">
                      <h3>The Benefits of Owning a Vacation Home</h3>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-12">
                  <Link
                    to="/Luxury-Home-Features"
                    className="news-box"
                    data-aos="fade-up"
                  >
                    <div className="news-img">
                      <img src={blog12} alt="img" />
                    </div>
                    <div className="news-text">
                      <h3>Luxury Home Features That Are Worth the Investment</h3>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-12">
                  <Link
                    to="/Interior-Design-Trends-for-Modern-Living-Spaces"
                    className="news-box"
                    data-aos="fade-up"
                  >
                    <div className="news-img">
                      <img src={blog13} alt="img" />
                    </div>
                    <div className="news-text">
                      <h3>Interior Design Trends for Modern Living Spaces</h3>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-12">
                  <Link
                    to="/How-to-Make-Your-Home-Eco-Friendly-and-Energy-Efficient"
                    className="news-box"
                    data-aos="fade-up"
                  >
                    <div className="news-img">
                      <img src={blog14} alt="img" />
                    </div>
                    <div className="news-text">
                      <h3>How to Make Your Home Eco-Friendly and Energy-Efficient</h3>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-12">
                  <Link
                    to="/Creating-the-Ultimate-Outdoor-Space-in-Your-Home"
                    className="news-box"
                    data-aos="fade-up"
                  >
                    <div className="news-img">
                      <img src={blog15} alt="img" />
                    </div>
                    <div className="news-text">
                      <h3>Creating the Ultimate Outdoor Space in Your Home: Landscaping Tips</h3>
                    </div>
                  </Link>
                </div>
                
              </div>
            </div>
          </div>
          <Sidebar/>
        </div>
      </div>
    </section>
  );
};

export default BlogList;