import React from "react";
import { Link } from "react-router-dom";
import Sidebar from "./sidebar";


import blog6 from "../img/blog-6.png";
import blog7 from "../img/blog-7.png";
import blog8 from "../img/blog-8.png";
import blog9 from "../img/blog-9.png";
import blog10 from "../img/blog-10.png";

// import "./style.css";

const BlogList = () => {
  return (
    <section className="blog-page-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-7">
            <div className="blog-left">
              <div className="row">
                <div className="col-lg-12">
                  <Link
                    to="/The-Impact-of-Location-on-Commercial-Property-Value"
                    className="news-box"
                    data-aos="fade-up"
                  >
                    <div className="news-img">
                      <img src={blog6} alt="img" />
                    </div>
                    <div className="news-text">
                      <h3>The Impact of Location on Commercial Property Value</h3>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-12">
                  <Link
                    to="/Office-vs-Retail"
                    className="news-box"
                    data-aos="fade-up"
                  >
                    <div className="news-img">
                      <img src={blog7} alt="img" />
                    </div>
                    <div className="news-text">
                      <h3>Office vs. Retail: Choosing the Right Commercial Property for Your Business</h3>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-12">
                  <Link
                    to="/Leasing-vs-Buying"
                    className="news-box"
                    data-aos="fade-up"
                  >
                    <div className="news-img">
                      <img src={blog8} alt="img" />
                    </div>
                    <div className="news-text">
                      <h3>Leasing vs. Buying</h3>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-12">
                  <Link
                    to="/Investing-in-Commercial-Real-Estate"
                    className="news-box"
                    data-aos="fade-up"
                  >
                    <div className="news-img">
                      <img src={blog9} alt="img" />
                    </div>
                    <div className="news-text">
                      <h3>Investing in Commercial Real Estate</h3>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-12">
                  <Link
                    to="/Evaluating-the-ROI-of-Commercial-Real-Estate-Investments"
                    className="news-box"
                    data-aos="fade-up"
                  >
                    <div className="news-img">
                      <img src={blog10} alt="img" />
                    </div>
                    <div className="news-text">
                      <h3>Evaluating the ROI of Commercial Real Estate Investments</h3>
                    </div>
                  </Link>
                </div>
                
              </div>
            </div>
          </div>
          <Sidebar/>
        </div>
      </div>
    </section>
  );
};

export default BlogList;